import axios from './BaseService'
import { postSigned } from './PostSigned'

const CALLS_URL = `${process.env.REACT_APP_API_URL}/calls/`

export async function getCalls(token, page, limit, search, status, ordenation) {
  const endPoint = `${CALLS_URL}list${page ? `?page=${page}` : ''}`
  const post = { limit, search, status, ordenation }
  return await postSigned(endPoint, post)
}

export const getCallMessages = async (callId, token) => {
  const endPoint = `${CALLS_URL}call/${callId}`
  const response = await axios.get(endPoint)
  return response.data
}

export const sendCallMessages = async (data, token) => {
  const endPoint = `${CALLS_URL}reply`
  const post = data
  return await postSigned(endPoint, post)
}
