import { encrypt } from '../utils/crypto'
import axios from './BaseService'
import { postSigned } from './PostSigned'

const WITHDRAWALS_URL = `${process.env.REACT_APP_API_URL}/withdraws/`

export async function getWithdrawals(page, limit, search, paymentMethod, status, ordenation) {
  const endPoint = `${WITHDRAWALS_URL}list${page ? `?page=${page}` : ''}`
  const post = { limit, search, paymentMethod, status, ordenation }
  return await postSigned(endPoint, post)
}

export async function getWithdrawalsFiles(page, limit) {
  const endPoint = `${WITHDRAWALS_URL}files${page ? `?page=${page}` : ''}`
  const post = { limit }
	return await postSigned(endPoint, post)
}

export async function payWithdraw(withdrawals, coin) {
  const endPoint = `${WITHDRAWALS_URL}payWithdraw`
  const post = { withdrawals, coin }
	return await postSigned(endPoint, post)
}

export async function generateFileWithdraw(withdrawals, coin) {
  const endPoint = `${WITHDRAWALS_URL}generatefilewithdraw`
  const post = { withdrawals, coin }
	return await postSigned(endPoint, post)
}

export async function doChargeback(id) {
  const endPoint = `${WITHDRAWALS_URL}chargeback/${id}`
  const response = await axios.get(endPoint)
  return response.data
}
