import { encrypt } from '../utils/crypto'
import axios from './BaseService'

export const postSigned = async (endPoint, post, method = 'post') => {
  const signature = encrypt(JSON.stringify(post))
  let response
  if (method === 'put') response = await axios.put(endPoint, post, { headers: { signature } })
  else if (method === 'patch') response = await axios.patch(endPoint, post, { headers: { signature } })
  else response = await axios.post(endPoint, post, { headers: { signature } })
  return response.data
}
