import axios from './BaseService'
import { postSigned } from './PostSigned'

const LIMITS_URL = `${process.env.REACT_APP_API_URL}/limits/`

export async function getLimits(page, token) {
  const url = `${LIMITS_URL}?page=${page}`

  const response = await axios.get(url)
  return response.data //{count, rows}
}

export async function getAllLimits(token) {
  const response = await axios.get(`${LIMITS_URL}all`)
  return response.data
}

export async function getActiveLimits() {
  const response = await axios.get(`${LIMITS_URL}active`)
  return response.data
}

export async function saveLimit(id, newLimit) {
  let endPoint = LIMITS_URL
  if (id) endPoint = `${LIMITS_URL}${id}`
  const post = newLimit
  return await postSigned(endPoint, post)
}

export async function deleteLimit(id) {
  const response = await axios.delete(`${LIMITS_URL}${id}`)
  return response.data
}

export async function startLimit(id) {
  const endPoint = `${LIMITS_URL}${id}`
  const post = { isActive: true }
  return await postSigned(endPoint, post, 'patch')
}

export async function stopLimit(id) {
  const endPoint = `${LIMITS_URL}${id}`
  const post = { isActive: false }
  return await postSigned(endPoint, post, 'patch')
}
