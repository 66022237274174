import axios from './BaseService'
import { postSigned } from './PostSigned'

const SETTINGS_URL = `${process.env.REACT_APP_API_URL}/settings` || 'http://localhost:3001/settings'

export async function getSettings() {
  const response = await axios.get(SETTINGS_URL)
  return response.data
}

export async function updateSettings(settings) {
	const endPoint = `${SETTINGS_URL}`
	const post = settings
		return await postSigned(endPoint, post, 'patch')
}

export async function getUserSettings() {
  const response = await axios.get(`${SETTINGS_URL}/user`)
  return response.data
}

export async function updateUserSettings(data) {
	const endPoint = `${SETTINGS_URL}/user`
	const post = data
	return await postSigned(endPoint, post, 'patch')
}
