import axios from './BaseService'
import { postSigned } from './PostSigned'

const API_URL = process.env.REACT_APP_API_URL

export async function doLogin(email, password) {
  const endPoint = `${API_URL}/login`
  const post = { email, password }
  return await postSigned(endPoint, post)
}

export async function validateToken() {
  const validateUrl = `${API_URL}/validateToken`
  const response = await axios.get(validateUrl)
  return response.data
}

export async function doLogout() {
  const endPoint = `${API_URL}/logout`
  const post = {}
  return await postSigned(endPoint, post)
}

export async function deactivateTwoFAAdm(id) {
  const endPoint = `${API_URL}/auth/2fa/deactivatetwofaadm`
  const post = { id }
  return await postSigned(endPoint, post)
}
