import axios from './BaseService'
import { postSigned } from './PostSigned'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/incomes`

export async function getPercentageIncomes() {
  const endPoint = `${ENDPOINT}/percentages`
  const response = await axios.get(endPoint)
  return response.data
}

export async function savePercentages(percentageIncomes) {
  const endPoint = `${ENDPOINT}/savepercentages`
const post = percentageIncomes
	return await postSigned(endPoint, post, 'put')
}
