import axios from './BaseService'
import { postSigned } from './PostSigned'

const USERS_URL = `${process.env.REACT_APP_API_URL}/users/`
const USERSMMN_URL = `${process.env.REACT_APP_API_URL}/usersmmn/`

export async function getActiveUsers() {
  const response = await axios.get(`${USERS_URL}active`)
  return response.data
}

export async function getUsersNonList(data) {
  const endPoint = `${USERSMMN_URL}active`
  const post = { search: data }
  return await postSigned(endPoint, post)
}

export async function getActiveUsersParam(param) {
  const response = await axios.get(`${USERS_URL}active?search=${param}`)
  return response.data
}

export async function getUsers(token, page, limit, search, exact, ordenation) {
  const endPoint = `${USERS_URL}list${page ? `?page=${page}` : ''}`
  const post = { limit, search, exact, ordenation }
  return await postSigned(endPoint, post)
}

export async function doConvert(token, id) {
  const endPoint = `${USERSMMN_URL}convert`
  const post = { id }
  return await postSigned(endPoint, post)
}

export const doVerifyNick = async nick => {
  const endPoint = `${process.env.REACT_APP_API_URL}/auth/verifyNick`
  const post = { nick }
  return await postSigned(endPoint, post)
}

export async function saveUser(id, newUser) {
  const endPoint = `${USERSMMN_URL}user/${id}`
  const post = newUser
  return await postSigned(endPoint, post, 'put')
}

export async function doClearWallet(wallet, id) {
  const response = await axios.delete(`${USERSMMN_URL}clearwallet/${wallet}/${id}`)
  return response.data
}

export async function saveBinaryUser(binary) {
  const endPoint = `${USERSMMN_URL}editBinaryUser`
  const post = binary
  return await postSigned(endPoint, post)
}

export async function deleteUser(id) {
  const response = await axios.delete(`${USERS_URL}${id}`)
  return response.data
}

export async function startUser(id) {
  const endPoint = `${USERS_URL}${id}/start`
  const post = {}
  return await postSigned(endPoint, post)
}

export async function stopUser(id) {
  const endPoint = `${USERS_URL}${id}/stop`
  const post = {}
  return await postSigned(endPoint, post)
}

export async function resetUserPassword(id) {
  const endPoint = `${USERS_URL}${id}/reset`
  const post = {}
  return await postSigned(endPoint, post)
}

export async function getTelegramIds(token, page, limit, search) {
  const endPoint = `${USERS_URL}telegramids${page ? `?page=${page}` : ''}`
  const post = { limit, search }
  return await postSigned(endPoint, post)
}

export async function getKycs(token, page, limit, search, exact, ordenation) {
  const endPoint = `${USERSMMN_URL}listkyc${page ? `?page=${page}` : ''}`
  const post = { limit, search, exact, ordenation }
  return await postSigned(endPoint, post)
}

export async function approveOrRejectKyc(token, id, action) {
  const endPoint = `${USERSMMN_URL}approveorrejectkyc`
  const post = { id, action }
  return await postSigned(endPoint, post)
}

export async function saveQuotas(post, id) {
  const endPoint = `${USERSMMN_URL}editquotas/${id}`
  return await postSigned(endPoint, post)
}
