import axios from './BaseService'
import { postSigned } from './PostSigned'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/quotas`

export async function simulation(token, percent) {
  const endPoint = `${ENDPOINT}/simulation`
  const post = { percent }
  return await postSigned(endPoint, post)
}

export async function payQuotas(token, percent) {
	const endPoint = `${ENDPOINT}/payquotas`
	const post = { percent }
	return await postSigned(endPoint, post)
}

export async function getQuotasPaid() {
  const endPoint = `${ENDPOINT}/paid`
  const response = await axios.get(endPoint)
  return response.data
}
