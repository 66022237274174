import axios from './BaseService'
import { postSigned } from './PostSigned'

const ORDERS_URL = `${process.env.REACT_APP_API_URL}/ordersmmn/`

export async function getOrders(token, page, limit, search, status, searchUser, ordenation, orderId, exact) {
  const endPoint = `${ORDERS_URL}list${page ? `?page=${page}` : ''}`
  const post = { limit, search, status, searchUser, ordenation, orderId, exact }
  return await postSigned(endPoint, post)
}

export async function payOrder(token, order, type) {
	const endPoint = `${ORDERS_URL}payOrder`
	const post = { order, type }
	return await postSigned(endPoint, post)
}
